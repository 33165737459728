<!-- PAGE LOADER -->
<div class="page-progress">
  <mat-progress-bar mode="indeterminate" *ngIf="moduleLoading"></mat-progress-bar>
</div>

<div class="content-grid" *ngIf="!currencyLoading">
  <div class="app-hub-content {{ forExtract?'bg_gradient_purple':''}}">
    <div class="app-sidebar-container" [ngClass]="{'full-width': !navService.sidebarState.sidenavOpen}">
      <!-- Sidebar -->
      <div class="app-sidebar-wrap">
        <app-sidebar class="sidebar-wrapper"></app-sidebar>
      </div>
      <!-- Content page -->
      <div class="app-content-wrap">
        <!-- Header -->
        <app-header [forExtract]="forExtract"></app-header>
        <div class="app--body-container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
<app-chatbot></app-chatbot>
<!-- PAGE LOADER -->
<!-- <div class="page-loader" *ngIf="moduleLoading">
  <mat-spinner class="m-auto"></mat-spinner>
</div> -->